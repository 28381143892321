<template>
  <v-dialog v-model="value" max-width="500" persistent>
    <v-card >
      <v-card-title class="titulo text-h4">Confirmar</v-card-title>
      <v-card-text>
          <p class="text text-h5">{{message}}</p>
      </v-card-text>
      <div class="text-end pa-4">
        <v-btn @click="confirmed" color="success" class="mr-4">Aceptar</v-btn>
        <v-btn text @click="$emit('cancel')">Cancelar</v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name:'Confirm',
  props:['value','message'],
  methods:{
    confirmed(){
      this.value=false    
      this.$emit('ok')
      }
  }

}
</script>

<style>

</style>